var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"app-modal__box",class:_vm.mode ? '' : 'performance__night'},[_c('div',{staticClass:"app-modal__in"},[_c('div',{staticClass:"app-modal__header d-flex f-between p-5 m-title-modal"},[_c('p',{staticClass:"large--title m-0",class:_vm.mode ? 'text__day2' : 'text__night2'},[_vm._v(_vm._s(_vm.$t("message.new_m", { m: _vm.$t("message.client"), })))]),_c('div',[_c('crm-store-update-close',{attrs:{"permission":_vm.$options.name,"button_type":'store',"loading":_vm.loadingButton},on:{"c-submit":function($event){return _vm.submit(true)},"c-close":function($event){return _vm.close()}}})],1)])]),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loadingData),expression:"loadingData"}],staticClass:"app-modal__body p-5 pb-0"},[_c('div',{staticClass:"timeline-items__right rounded-sm w-100 p-4",class:_vm.mode ? 'table__myday' : 'table__mynight'},[_c('el-row',{attrs:{"gutter":20}},[_c('el-form',{ref:"form",attrs:{"status-icon":"","model":_vm.form,"rules":_vm.rules}},[_c('el-col',{attrs:{"span":12}},[_c('div',{staticClass:"app-form__group mb-4 width-auto"},[_c('span',{staticClass:"input--label d-block mb-2",class:_vm.mode ? 'text__day2' : 'text__night2'},[_vm._v(_vm._s(_vm.$t("message.nameGiven")))]),_c('el-form-item',{attrs:{"prop":"name","size":"mini"}},[_c('el-input',{class:_vm.mode ? 'input__day' : 'input__night',attrs:{"placeholder":_vm.$t('message.nameGiven'),"size":"medium"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1)],1)]),_c('el-col',{attrs:{"span":24}},[_c('div',{staticClass:"app-form__group mb-4 width-auto"},[_c('el-tree',{ref:"model_permissions",class:_vm.mode ? '' : 'tree__night',attrs:{"data":[
                    {
                      model_type_id: 0,
                      name: 'Модели компании',
                      table_name: 'companies',
                      has_children: _vm.permissions.model_permissions,
                    },
                  ],"show-checkbox":"","node-key":"key","props":_vm.defaultProps}}),_c('el-tree',{ref:"permissions",class:_vm.mode ? '' : 'tree__night',attrs:{"data":[
                    {
                      name: 'Разрешения',
                      has_children: _vm.permissions.permissions,
                    },
                  ],"show-checkbox":"","node-key":"id","props":_vm.defaultProps}})],1)])],1)],1)],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }