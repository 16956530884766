<template>
  <div class="p-pegas">
    <div
      class="card-table"
      :class="mode ? 'card__tableday' : 'card__tablenight'"
    >
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="caz-blocks-sarcho-title">
              <div
                class="content-title d-flex align-center mr-2"
                :class="mode ? 'content__titleday' : 'content__titlenight'"
              >
                {{ $t("message.role") }}
              </div>
              <div class="block-sarche">
                <div class="header__search">
                  <el-input
                    :placeholder="$t('message.find')"
                    :class="mode ? 'input__day' : 'input__night'"
                    prefix-icon="el-icon-search"
                    v-model="filterForm.search"
                    size="small"
                    round
                    style="width: 100% !important"
                  ></el-input>
                </div>
              </div>
            </div>
          </el-col>

          <el-col :span="12" class="flex-style text-right">
            <crm-create-and-column-settings
              :permission="$options.name"
              @c-create="drawerCreate = true"
              :class="mode ? 'button__settingsday' : 'button__settingsnight'"
              :columns="columns"
              @c-change="updateColumn"
              :v_can_create="'roles.create'"
            >
            </crm-create-and-column-settings>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->

      <div class="card-table-header table-crm-smart">
        <table
          :class="mode ? 'table__myday' : 'table__mynight'"
          class="table-my-code table-bordered"
        >
          <thead>
            <tr>
              <th class="w50p" v-if="columns.id.show">
                {{ columns.id.title }}
              </th>

              <th v-if="columns.name.show">
                {{ columns.name.title }}
              </th>

              <th v-if="columns.created_at.show">
                {{ columns.created_at.title }}
              </th>

              <th v-if="columns.updated_at.show">
                {{ columns.updated_at.title }}
              </th>

              <th v-if="columns.settings.show">
                {{ columns.settings.title }}
              </th>
            </tr>

            <tr class="filter_sorche">
              <th v-if="columns.id.show">
                <crm-input
                  v-model="filterForm.id"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.id.title"
                  :type="'text'"
                  :className="'id_input'"
                ></crm-input>
              </th>

              <th v-if="columns.name.show">
                <crm-input
                  v-model="filterForm.name"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.name.title"
                  :type="'text'"
                ></crm-input>
              </th>

              <th v-if="columns.created_at.show">
                <crm-date-picker
                  v-model="filterForm.created_at"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.created_at.title"
                ></crm-date-picker>
              </th>

              <th v-if="columns.updated_at.show">
                <crm-date-picker
                  v-model="filterForm.updated_at"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.updated_at.title"
                ></crm-date-picker>
              </th>

              <th class="settinW" v-if="columns.settings.show"></th>
            </tr>
          </thead>

          <transition-group name="flip-list" tag="tbody">
            <tr v-for="role in list" :key="role.id" class="cursor-pointer">
              <td v-if="columns.id.show">{{ role.id }}</td>
              <td v-if="columns.name.show">
                {{ role.name }}
              </td>

              <td v-if="columns.created_at.show">
                {{ role.created_at }}
              </td>

              <td v-if="columns.updated_at.show">{{ role.updated_at }}</td>

              <td v-if="columns.settings.show" class="settings-td">
                <crm-settings
                  :name="$options.name"
                  :model="role"
                  :actions="actions"
                  :permissionShow="'roles.update'"
                  :permissionDestroy="'roles.destroy'"
                  @edit="edit"
                  @delete="destroy"
                >
                </crm-settings>
              </td>
            </tr>
          </transition-group>
        </table>
        <div class="my___pagination">
          <crm-pagination
            @c-change="updatePagination"
            :class="mode ? 'pagination__day' : 'pagination__night'"
            :pagination="pagination"
          ></crm-pagination>
        </div>
      </div>
      <div class="app-modal app-modal__full">
        <el-drawer
          :with-header="false"
          :wrapperClosable="false"
          :visible.sync="drawerCreate"
          size="70%"
          ref="drawerCreate"
          @opened="drawerOpened('drawerCreateChild')"
          @closed="drawerClosed('drawerCreateChild')"
        >
          <crm-create
            ref="drawerCreateChild"
            drawer="drawerCreate"
          ></crm-create>
        </el-drawer>
        <el-drawer
          :with-header="false"
          :wrapperClosable="false"
          :visible.sync="drawerUpdate"
          size="70%"
          ref="drawerUpdate"
          @opened="drawerOpened('drawerUpdateChild')"
          @closed="drawerClosed('drawerUpdateChild')"
        >
          <crm-update
            :selectedItem="selectedItem"
            ref="drawerUpdateChild"
            drawer="drawerUpdate"
          ></crm-update>
        </el-drawer>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import list from "@/utils/mixins/list";
import Pagination from "@/components/pagination";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "RoleController",
  mixins: [list],
  components: {
    CrmCreate,
    Pagination,
    CrmUpdate
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      roles: "roles/list",
      list: "roles/list",
      columns: "roles/columns",
      pagination: "roles/pagination",
      statues: "roles/statues",
      filter: "roles/filter",
      sort: "roles/sort",
      mode: "MODE"
    }),
    actions: function() {
      return ["edit", "delete"];
    }
  },
  methods: {
    ...mapActions({
      updateList: "roles/index",
      setPagination: "roles/setPagination",
      updateSort: "roles/updateSort",
      updateFilter: "roles/updateFilter",
      updateColumn: "roles/updateColumn",
      updatePagination: "roles/updatePagination",
      show: "roles/show",
      empty: "roles/empty",
      delete: "roles/destroy",
      refreshData: "roles/refreshData"
    })
  }
};
</script>
