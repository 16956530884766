<template>
  <div>
    <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0" :class="mode ? 'text__day2' : 'text__night2'">{{
                            $t("message.new_m", {
                                m: $t("message.role"),
                            })
                        }}</p>
          <div>
            <crm-store-update-close
              :permission="$options.name"
              :button_type="'store'"
              :loading="loadingButton"
              @c-submit="submit(true)"
              @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>

      <div class="app-modal__body p-5 pb-0" v-loading="loadData">
        <div class="timeline-items__right rounded-sm w-100 p-4" :class="mode ? 'table__myday' : 'table__mynight'">
          <el-row :gutter="20">
            <el-col :span="12">
              <div class="app-form__group mb-4 width-auto">
                <span class="input--label d-block mb-2" :class="mode ? 'text__day2' : 'text__night2'">{{$t("message.nameGiven")}}</span>
                <el-form ref="form" status-icon :model="form" :rules="rules">
                  <el-form-item prop="name" size="mini">
                    <el-input
                      :placeholder="$t('message.nameGiven')"
                      v-model="form.name"
                      size="medium"
                      :class="mode ? 'input__day' : 'input__night'"
                    ></el-input>
                  </el-form-item>
                </el-form>
              </div>
            </el-col>
            <!-- end col -->
            <el-col :span="24" >
              <div class="app-form__group mb-4 width-auto">
                <el-tree
                  :data="[
                    {
                      model_type_id: 0,
                      name: 'Модели компании',
                      table_name: 'companies',
                      has_children: permissions.model_permissions,
                    },
                  ]"
                  show-checkbox
                  node-key="key"
                  :props="defaultProps"
                  ref="model_permissions"
                  :class="mode ? '' : 'tree__night'"
                >
                </el-tree>
                <el-tree
                  :data="[
                    {
                      name: 'Разрешения',
                      has_children: permissions.permissions,
                    },
                  ]"
                  show-checkbox
                  node-key="id"
                  :props="defaultProps"
                  ref="permissions"
                  :class="mode ? '' : 'tree__night'"
                >
                </el-tree>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
export default {
  mixins: [drawer, form],
  name: "RoleController",
  data() {
    return {
      defaultProps: {
        children: "has_children",
        label: "name",
      },
      loadData: false,
    };
  },
  computed: {
    ...mapGetters({
      permissions: "roles/permissions",
      rules: "roles/rules",
      model: "roles/model",
      columns: "roles/columns",
      mode:"MODE"
    }),
  },
  components: {

  },
  methods: {
    ...mapActions({
      fetchData: "roles/create",
      save: "roles/store",
    }),

    afterOpen() {
      this.get();
    },
    get() {
      if (!this.loadData) {
        this.fetchData()
          .then((res) => {})
          .catch((err) => {
            this.$alert(err);
          });
      }
    },
    submit(close = false) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          let model_permissions = [];
          let model_checked_permissions =
            this.$refs.model_permissions.getCheckedNodes();
          for (let key in model_checked_permissions) {
            if (model_checked_permissions.hasOwnProperty(key)) {
              let checkedPermission = model_checked_permissions[key];
              model_permissions.push({
                model_type_id: checkedPermission.model_type_id,
                table_name: checkedPermission.table_name,
              });
            }
          }
          let permissions = [];
          let checkedPermissions = this.$refs.permissions.getCheckedNodes();
          for (let key in checkedPermissions) {
            if (checkedPermissions.hasOwnProperty(key)) {
              let checkedPermission = checkedPermissions[key];
              permissions.push(checkedPermission.id);
            }
          }
          this.form["model_permissions"] = model_permissions.filter(function (
            e
          ) {
            return e;
          });
          this.form["permissions"] = permissions.filter(function (e) {
            return e;
          });
          this.loadingButton = true;
          this.save(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.$alert(res);
              this.parent().listChanged();
              if ([201, 200].includes(res.status)) this.close();
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
  .width-auto{
      width: auto !important;
  }
  .width-auto .is-expanded{
      width: auto !important;
  }
  .tree__night{
    background: transparent !important;
    .is-focusable{
      background-color: transparent !important;
      
    }
    .el-tree-node__content{
      &:hover{
        background-color: transparent !important;
      }
    }
    .el-tree-node{
      &:focus{
        .el-tree-node__content{
          background: transparent !important;
          background-color:transparent !important;

        }
        background: transparent !important;
      }
    }
    .el-tree-node:focus>.el-tree-node__content{
      background: transparent !important;
    }
    .el-tree-node__label{
      color: #fff;
    }
  }
</style>
